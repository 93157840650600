.mainCarouselContainer {
    overflow: hidden;

    figure {
        margin: 0;
    }

    .slick-prev{
        translate: 42px;
    }

    .slick-next {
        translate: -42px;
    }

    .slick-dots {
        translate: 0 -25px;

        li button{
            width: 13px;
            height: 13px;
        }
    }

    .mainbanner-container {
        img {
            width: 100%;
            height: auto;
            display: inline;
            height: auto;

        }
    }
}

@media (max-width: 991px){
    .mainCarouselContainer .slick-dots {
        translate: 0 0;
        margin-bottom: 0.25rem;
    }
}

@media (max-width: 768px){
    .mainCarouselContainer .slick-dots {
        li button{
            width: 7px;
            height: 7px;
        }
    }
}
